import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { BulletPoints } from '@/components/common/bulletpoints';
import { CaptionHeadingDescription } from '@/components/common/CaptionHeadingDescription';
import { Image } from '@/components/common/image';
import { Heading, HeadingLevel } from '@/components/common/MarkUp';
import { PortableText } from '@/components/common/utils';
import { Section } from '@/components/layout/Section';
import { subheadingSmall } from '@/styles/typography';

import type { SanityCollageTextType } from '@/types/sanity';
import type { BlockWithAudience } from '@/types/shared';

export interface CollageTextProps
  extends SanityCollageTextType,
    BlockWithAudience {}

const CollageText = ({
  heading,
  images,
  title,
  text,
  subheading,
  bulletPoints,
  backgroundColour,
  blockSpacing,
  paddingAdjustment,
  pageAudience,
  isAudienceSwitcherEnabled,
}: CollageTextProps) => {
  const hasTwoImages = images.length === 2;
  const [imageOne, imageTwo, ...otherImages] = images;
  const backgroundColourToken =
    backgroundColour?.token || '--background-subtle';
  return (
    <Section
      verticalPadding={paddingAdjustment ?? true}
      spacing={blockSpacing}
      backgroundColour={backgroundColourToken}
      className="collage-text"
    >
      <HeadingLevel>
        <Grid>
          <Images hasTwoImages={hasTwoImages}>
            <LeftGrid>
              {[imageOne, imageTwo].map((image) => (
                <div key={image.asset._ref}>
                  <StyledImage
                    {...image}
                    sizes="(max-width: 976px) 50vw, (max-width: 1440px) 25vw, 340px"
                    objectFit="cover"
                    radius="l"
                  />
                </div>
              ))}
            </LeftGrid>
            {otherImages.length > 0 && (
              <RightGrid>
                {otherImages.map((image) => (
                  <div key={image.asset._ref}>
                    <StyledImage {...image} objectFit="cover" radius="l" />
                  </div>
                ))}
              </RightGrid>
            )}
          </Images>
          <TextWrapper>
            <StyledCaptionHeadingDescription
              caption={title}
              heading={heading}
              description={subheading}
              mobileAlignment="center"
              padding={{
                caption: 'x-small',
                captionMobile: 'x-small',
              }}
            />
            {!!text && (
              <ParagraphContainer>
                <PortableText
                  value={text}
                  audienceConfig={{ pageAudience, isAudienceSwitcherEnabled }}
                />
              </ParagraphContainer>
            )}
            {!!bulletPoints && (
              <BulletPoints bulletPointStyle="hexagon" paragraphFontSize="md">
                <PortableText
                  value={bulletPoints}
                  audienceConfig={{ pageAudience, isAudienceSwitcherEnabled }}
                />
              </BulletPoints>
            )}
          </TextWrapper>
        </Grid>
      </HeadingLevel>
    </Section>
  );
};

const Grid = styled.div`
  ${screen.md} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-800);
    align-items: center;
  }

  ${screen.lg} {
    gap: var(--spacing-1000);
  }
`;

const TextWrapper = styled.div`
  text-align: center;

  h3 {
    margin-top: var(--spacing-x-small);
    margin-bottom: var(--spacing-xx-small);

    ${screen.md} {
      max-width: 400px;
    }
  }

  ul {
    padding-top: var(--spacing-x-small);
  }

  li {
    justify-content: center;
    ${screen.md} {
      justify-content: flex-start;
    }
  }

  ${screen.md} {
    text-align: left;
    flex: 1 0 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const StyledCaptionHeadingDescription = styled(CaptionHeadingDescription)`
  ${Heading} {
    padding-bottom: var(--spacing-xx-small);
  }
`;

const StyledImage = styled(Image)`
  height: 100%;
  border-radius: 4px;
`;

const LeftGrid = styled.div`
  grid-template-rows: 200px 200px;
  padding: var(--spacing-500) 0;

  ${screen.sm} {
    grid-template-rows: 240px 240px;
  }

  ${screen.md} {
    grid-template-rows: 280px 280px;
  }
`;

const RightGrid = styled.div`
  grid-template-rows: auto 200px auto;

  ${screen.sm} {
    grid-template-rows: auto 240px auto;
  }

  ${screen.md} {
    grid-template-rows: auto 280px auto;
  }
`;

const Images = styled.div<{ hasTwoImages: boolean }>`
  display: grid;
  grid-template-columns: ${({ hasTwoImages }) =>
    hasTwoImages ? '1fr' : '1fr 1fr'};
  grid-template-rows: auto;
  gap: var(--spacing-small);
  margin-bottom: var(--spacing-400);

  > div {
    display: grid;
    gap: var(--spacing-small);
  }

  ${screen.md} {
    margin-bottom: 0;
  }
`;

const ParagraphContainer = styled.div`
  p {
    ${subheadingSmall}
  }
`;

export { CollageText };
